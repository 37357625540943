<script setup lang="ts">
import type { ILeaseReportTemplate } from '@portfolio'

import { VueFinalModal, useModal } from 'vue-final-modal'
import type { Sector } from '@register'
import { leaseSectorReportOptions } from '~/constants/LeaseSector'
import TemplateCustomSaveModal from '@reports/components/Template/TemplateCustomSaveModal.vue'

interface Props {
  template: ILeaseReportTemplate
  divisionId: string
}
const props = defineProps<Props>()

const emit = defineEmits<{
  download: [template: ILeaseReportTemplate]
  save: [templateId: string]
  view: []
  close: []
}>()

/* === SELECTED ===*/
const selectedFields = ref<string[]>([
  ...(props.template.template?.fields ?? []),
])
const sector = ref<Sector | undefined>(props.template.sector)
const resultTemplate = computed<ILeaseReportTemplate>(() => ({
  ...props.template,
  sector: sector.value,
  template: {
    fields: selectedFields.value,
  },
}))
/* === SELECTED ===*/

/* === SAVE === */
const { open: onSave, close } = useModal({
  component: TemplateCustomSaveModal,
  attrs: {
    divisionId: props.divisionId,
    // @ts-expect-error - Reactivity
    template: resultTemplate,
    onSave: (_templateId) => {
      close()
      emit('save', _templateId)
    },
    onCancel: () => close(),
    onClose: () => close(),
  },
})
/* === SAVE === */

/* === VIEW ===*/
const isCustom = computed(() => {
  if (props.template.id === 'custom') return true
  if (sector.value !== props.template.sector) return true
  return (
    JSON.stringify(selectedFields.value) !==
    JSON.stringify(props.template.template?.fields ?? [])
  )
})
const query = useRouteQuery()
const viewURI = computed(() => ({
  path: `/division/${props.divisionId}/reports/view`,
  query: {
    ...query.value,
    template: isCustom.value ? 'custom' : (props.template.id as string),
    fieldIds: isCustom.value ? selectedFields.value : undefined,
    sector: sector.value,
  },
}))
/* === VIEW ===*/

/* === LISTENERS ===*/
const onDownload = () => emit('download', resultTemplate.value)
const onView = () => emit('view')
const onClose = () => emit('close')
/* === LISTENERS ===*/
</script>

<template>
  <VueFinalModal
    class="top-0 flex items-start justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
    :content-transition="
      {
        'enter-active-class': 'transition duration-300 ease-in-out transform',
        'enter-from-class': '-translate-y-6 opacity-0',
        'enter-to-class': 'translate-y-0 opacity-100',
        'leave-active-class': 'transition duration-300 ease-in-out transform',
        'leave-to-class': 'translate-y-6 opacity-0',
        'leave-from-class': '-translate-y-0 opacity-100',
      } as any
    "
  >
    <ModalContainer
      title="Customise template"
      :close-on-click="false"
      container-class="max-w-[1000px] bg-gray-800 border-gray-750 dark:bg-gray-800 dark:border-gray-750"
      body-class="p-0"
      @close="onClose"
    >
      <div class="flex flex-col transition-all">
        <div class="flex w-full flex-col">
          <div class="flex p-6">
            <FormKit
              v-model="sector"
              type="listbox"
              :options="leaseSectorReportOptions"
              outer-class="w-[300px]"
            >
              <template #prefix>
                <div
                  class="flex items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                >
                  Sector
                </div>
              </template>
            </FormKit>
          </div>

          <ReportsTransferList
            v-model="selectedFields"
            :division-id="divisionId"
          />

          <div class="flex justify-end gap-2 p-6">
            <Button
              v-if="isCustom"
              color="secondary"
              class="flex justify-center self-end whitespace-nowrap"
              @click="onSave"
            >
              Save as template
            </Button>
            <Button
              color="transparentSecondary"
              class="flex justify-center self-end whitespace-nowrap"
              @click="onDownload"
            >
              Download report
            </Button>
            <Button
              :to="viewURI"
              color="primary"
              class="flex justify-center self-end whitespace-nowrap"
              @click="onView"
            >
              View report
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
